<template>
  <div class="animated fadeIn">

    <b-tabs
      lazy
    >
      <b-tab @click="tabChanged" class="px-md-3 px-0">
        <template slot="title">
          <p>Alocação</p>
        </template>

        <allocation-rule-list
          ref="allocationRuleList"
          :types="types"
          orderType="A"
        >
        </allocation-rule-list>

        <div class="mt-3"></div>

        <allocation-sort-field-list
          ref="allocationSortFieldList"
          :fieldOptions="allocationFieldOptions"
          orderType="A"
        ></allocation-sort-field-list>
      </b-tab>

      <b-tab @click="tabChanged" class="px-md-3 px-0">
        <template slot="title">
          <p>Separação</p>
        </template>

        <allocation-rule-list
          ref="separationRuleList"
          :types="types"
          orderType="S"
        >
        </allocation-rule-list>

        <div class="mt-3"></div>

        <allocation-sort-field-list
          ref="separationSortFieldList"
          :fieldOptions="separationFieldOptions"
          orderType="S"
        ></allocation-sort-field-list>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import shared from '@/shared/shared'
import AllocationRuleList from './list'
import AllocationSortFieldList from './allocation-sort-fields/list'

export default {
  name: 'allocation-rules-index',
  components: { AllocationRuleList, AllocationSortFieldList },

  data() {
    return {
      types: [],
      allocationFieldOptions: [],
      separationFieldOptions: [],
      activeTabIndex: 0
    }
  },

  mounted() {
    this.$store.dispatch('app/changePage', { title:'Regras', size: 4 });
    this.types = shared.getAllocationRuleTypes();
    this.allocationFieldOptions = shared.getAllocationSortFieldOptions().filter(o => !o.orderType || o.orderType === 'A');
    this.separationFieldOptions = shared.getAllocationSortFieldOptions().filter(o => !o.orderType || o.orderType === 'S');
    this.tabChanged();
  },

  methods: {

    tabChanged() {
      this.$nextTick(() => {
        this.getRuleList().init();
        this.getSortFieldList().init();
      });
    },

    getRuleList() {
      return this.$refs.allocationRuleList || this.$refs.separationRuleList;
    },

    getSortFieldList() {
      return this.$refs.allocationSortFieldList || this.$refs.separationSortFieldList;
    }

  }
}
</script>
