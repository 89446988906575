<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">Regras</div>
      <div class="card-body">

        <div v-if="loadingRows || saving" class="mb-5 pb-5">
          <loading-animation />
        </div>

        <b-row class="mx-md-n3 mx-1 justify-content-start mb-3 mt-0">
          <div class="col-sm-auto col-12">
            <button type="button" class="btn btn-success mobile-btn-100" @click="openNewRuleModal" :disabled="buttonsDisabled || saving">Adicionar Regra</button>
          </div>
        </b-row>

        <div class="row py-3 border mx-0 bg-dark px-3">
          Endereço padrão do item
        </div>

        <div v-if="!loadingRows && !rules.length">
          <div class="row bg-light mx-0 py-3">
            <div class="col text-center">
              Nenhuma regra cadastrada
            </div>
          </div>
        </div>

        <div v-if="!loadingRows && rules.length">

          <draggable v-model="rules" group="rules" @end="onRuleDrop">
            <div v-for="(rule, index) in rules" class="row py-3 border mx-0 cursor-grab" :key="index"
              :class="{ 'bg-light': index % 2 != 0 }">
              <div class="col-sm col-12">
                <span class="font-weight-bold">Descrição: </span>{{rule.description}}
              </div>
              <div class="col-sm col-12">
                <span class="font-weight-bold">Regra: </span>{{rule.typeLabel}}
              </div>
              <div class="col-sm-auto col-12 pl-sm-0 pl-3">
                <b-button size="sm" @click.prevent="editItem(index)" :disabled="buttonsDisabled"
                  class="ml-sm-1 ml-0 mr-sm-1 mr-4 mobile-btn-45">
                  <i class="fa fa-edit"></i> Editar
                </b-button>
                <b-button size="sm" variant="danger" @click.prevent="openDeleteModal(index)"
                  class="ml-sm-1 ml-2 mobile-btn-45" :disabled="buttonsDisabled">
                  <i class="fa fa-trash"></i> Remover
                </b-button>
              </div>
            </div>
          </draggable>

        </div>

        <div class="row py-3 border mx-0 bg-dark px-3">
          Endereços do item
        </div>
        <div class="row py-3 border mx-0 bg-dark px-3">
          Endereços de item indefinido
        </div>

        <confirmation-modal
          id="rule-delete-confirmation-modal"
          msg="Tem certeza de que deseja remover essa regra?"
          :ok="deleteItem"
        ></confirmation-modal>

        <b-modal
          centered
          size="xl"
          lazy
          title="Formulário de Regras de Alocação"
          header-bg-variant="info"
          :hide-footer="true"
          v-model="showFormModal"
          @hidden="formModalHidden"
        >
          <b-container>
            <allocation-rule-form-modal
              ref="allocationRuleFormModal"
              :saved="ruleSaved"
              :rules="rules"
              :types="types"
              :orderType="orderType"
            ></allocation-rule-form-modal>
          </b-container>
        </b-modal>

      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from '@/service'
import { LoadingAnimation } from '@/components/loaders'
import draggable from 'vuedraggable'
import ConfirmationModal from '@/components/common/confirmation-modal'
import shared from '@/shared/shared'
import AllocationRuleFormModal from "./form-modal";

export default {
  name: 'allocation-rule-list',
  components: { LoadingAnimation, draggable, ConfirmationModal, AllocationRuleFormModal },

  props: {
    orderType: {
      type: String,
      require
    },
    types: {
      type: Array,
      require
    }
  },

  data() {
    return {
      rules: [],
      loadingRows: false,
      saving: false,
      editIndex: -1,
      deleteIndex: null,
      currentRule: {},
      showFormModal: false
    }
  },

  computed: {
    buttonsDisabled() {
      return this.saving || this.editIndex > -1;
    }
  },

  methods: {
    init() {
      this.findAll();
    },

    findAll() {
      let conditions = [];

      if (this.orderType === 'A') {
        conditions.push({
          logicalOperator: 'OR',
          conditions: [
            {
              field: 'orderType',
              value: this.orderType
            },
            {
              field: 'orderType',
              conditionalOperator: 'IS_NULL'
            }
          ]
        });
      } else {
        conditions.push({
          field: 'orderType',
          value: this.orderType
        });
      }

      this.loadingRows = true;
      httpClient.post(
        `${process.env.VUE_APP_API_URL}allocation-rules/condition?sort=priority,asc`,
        {
          conditions: conditions
        }
      ).then(({ data }) => {
        this.loadingRows = false;
        data.data.content.forEach(r => this.setTypeLabel(r));
        this.rules = data.data.content;
      })
      .catch((error) => {
        this.loadingRows = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    onRuleDrop() {
      let newRules = [];

      for (let i = 0; i < this.rules.length; i++) {
        let r = Object.assign({}, this.rules[i]);
        r.priority = i;
        newRules.push(r);
      }

      let changedRules = [];

      newRules.forEach(newRule => {
        let oldRule = this.rules.find(r => r.id === newRule.id);

        if (oldRule && oldRule.priority !== newRule.priority) {
          changedRules.push(newRule);
        }
      });

      if (changedRules.length) {
        this.saving = true;
        httpClient.put(`${process.env.VUE_APP_API_URL}allocation-rules/multiple?patch=true`, changedRules.map(r => {
          return {
            id: r.id,
            priority: r.priority
          }
        })).then(({ data }) => {
          this.saving = false;

          if (!data.errors || !data.errors.length) {
            this.$notify.success('Prioridades das regras atualizadas');
            data.data.forEach(r => this.setTypeLabel(r));
            this.rules = data.data;
          } else {
            this.$notify.warn('Não foi possível salvar todas as regras');
            data.errors.forEach(error => {
              this.$notify.textError(error);
            });
          }
        })
        .catch((error) => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      }
    },

    editItem(index) {
      this.editIndex = index;
      this.showFormModal = true;

      this.$nextTick(() => {
        this.$refs.allocationRuleFormModal.loadRule(index);
      });
    },

    openDeleteModal(index) {
      this.deleteIndex = index;
      this.$bvModal.show('rule-delete-confirmation-modal');
    },

    deleteItem() {
      this.saving = true;
      httpClient.delete(`${process.env.VUE_APP_API_URL}allocation-rules`, this.rules[this.deleteIndex].id)
      .then(({ data }) => {
        this.saving = false;
        this.$notify.success('Regra removida com sucesso');
        this.rules.splice(this.deleteIndex, 1);
        this.deleteIndex = null;
      })
      .catch((error) => {
        this.saving = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    openNewRuleModal() {
      this.editIndex = -1;
      this.showFormModal = true;

      this.$nextTick(() => {
        this.$refs.allocationRuleFormModal.loadRule();
      });
    },

    ruleSaved(savedRule) {
      savedRule = this.setTypeLabel(savedRule);

      if (this.editIndex > -1) {
        this.rules[this.editIndex] = savedRule;
      } else {
        this.rules.push(savedRule);
      }

      this.showFormModal = false;
    },

    formModalHidden() {
      this.editIndex = -1;
    },

    setTypeLabel(rule) {
      if (rule.ruleCode) {
        let type = this.types.find(t => t.value === rule.ruleCode);

        if (type) {
          rule.typeLabel = type.label;
        }
      }

      return rule;
    }
  }
}
</script>
