<template>
  <div class="card">
    <div class="card-header">Ordenação</div>
    <div class="card-body">

      <div class="card">
        <div class="card-header">{{formTitle}}</div>
        <div class="card-body">
          <div class="row mx-0 mx-md-n3">

            <div class="col">
              <model-list-select
                :list="fieldOptions"
                option-value="value"
                option-text="label"
                v-model="selectedOption"
                placeholder="Digite para pesquisar..."
              />

              <div v-if="selectedOption && selectedOption.hint" class="mt-1">
                <small class="text-muted">{{selectedOption.hint}}</small>
              </div>
            </div>

            <!--<div class="col-sm-auto col-12 px-0">
              <button type="button" class="btn btn-secondary mobile-btn-100" @click="cancelSave" :disabled="buttonsDisabled || editIndex < 0">Cancelar</button>
            </div>-->

            <div class="col-auto">
              <button type="button" class="btn btn-success" @click="saveRow" :disabled="buttonsDisabled">
                <i class="fa fa-save"></i> Adicionar
              </button>
            </div>

          </div>
        </div>
      </div>

      <div v-if="loadingRows" class="mb-5 pb-5">
        <loading-animation />
      </div>

      <div v-if="!loadingRows && !rows.length">
        <div class="row bg-light mx-0 py-3">
          <div class="col text-center">
            Nenhum campo cadastrado
          </div>
        </div>
      </div>

      <div v-if="!loadingRows && rows.length">

        <draggable v-model="rows" group="rows" @end="onRowDrop">
          <div v-for="(row, index) in rows" class="row py-3 border mx-0 cursor-grab" :key="index"
            :class="{ 'bg-light': index % 2 != 0 }">
            <div class="col">
              <!--<span class="font-weight-bold">Campo: </span>-->{{row.fieldLabel}}
            </div>
            <div class="col-auto pl-sm-0 pl-3">
              <!--<b-button size="sm" @click.prevent="editItem(index)" :disabled="buttonsDisabled"
                class="ml-sm-1 ml-0 mr-sm-1 mr-4 mobile-btn-45">
                <i class="fa fa-edit"></i> Editar
              </b-button>-->
              <b-button size="sm" variant="danger" @click.prevent="openDeleteModal(index)"
                class="ml-sm-1 ml-2" :disabled="buttonsDisabled">
                <i class="fa fa-trash"></i> Remover
              </b-button>
            </div>
          </div>
        </draggable>

      </div>

      <confirmation-modal
        id="sort-field-delete-confirmation-modal"
        msg="Tem certeza de que deseja remover esse campo?"
        :ok="deleteItem"
      ></confirmation-modal>

    </div>
  </div>
</template>

<script>
import { httpClient } from '@/service'
import { LoadingAnimation } from '@/components/loaders'
import draggable from 'vuedraggable'
import ConfirmationModal from '@/components/common/confirmation-modal'
import shared from '@/shared/shared'
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'allocation-sort-field-list',
  components: { LoadingAnimation, draggable, ConfirmationModal, ModelListSelect },

  props: {
    orderType: {
      type: String,
      require
    },
    fieldOptions: {
      type: Array,
      require
    }
  },

  data() {
    return {
      rows: [],
      loadingRows: false,
      saving: false,
      editIndex: -1,
      deleteIndex: null,
      currentRow: {},
      selectedOption: {}
    }
  },

  mounted() {
    this.loadRow(-1);
  },

  computed: {
    buttonsDisabled() {
      return this.saving || this.editIndex > -1;
    },

    formTitle() {
      return this.editIndex > -1 ? 'Editar campo' : 'Novo campo'
    }
  },

  methods: {
    init() {
      this.findAll();
    },

    findAll() {
      let conditions = [{
        field: 'orderType',
        value: this.orderType
      }];

      this.loadingRows = true;
      httpClient.post(
        `${process.env.VUE_APP_API_URL}allocation-sort-fields/condition?sort=priority,asc`,
        {
          conditions: conditions
        }
      ).then(({ data }) => {
        this.loadingRows = false;
        data.data.content.forEach(r => this.setFieldLabel(r));
        this.rows = data.data.content;
      })
      .catch((error) => {
        this.loadingRows = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    onRowDrop() {
      let newRows = [];

      for (let i = 0; i < this.rows.length; i++) {
        let r = Object.assign({}, this.rows[i]);
        r.priority = i;
        newRows.push(r);
      }

      let changedRows = [];

      newRows.forEach(newRow => {
        let oldRow = this.rows.find(r => r.id === newRow.id);

        if (oldRow && oldRow.priority !== newRow.priority) {
          changedRows.push(newRow);
        }
      });

      if (changedRows.length) {
        this.saving = true;
        httpClient.put(`${process.env.VUE_APP_API_URL}allocation-sort-fields/multiple?patch=true`, changedRows.map(r => {
          return {
            id: r.id,
            priority: r.priority
          }
        })).then(({ data }) => {
          this.saving = false;

          if (!data.errors || !data.errors.length) {
            this.$notify.success('Prioridades dos campos atualizadas');
            data.data.forEach(r => this.setFieldLabel(r));
            this.rules = data.data;
          } else {
            this.$notify.warn('Não foi possível salvar todos os campos');
            data.errors.forEach(error => {
              this.$notify.textError(error);
            });
          }
        })
        .catch((error) => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      }
    },

    editItem(index) {
      this.editIndex = index;

      this.$nextTick(() => {
        this.loadRow(index);
      });
    },

    openDeleteModal(index) {
      this.deleteIndex = index;
      this.$bvModal.show('sort-field-delete-confirmation-modal');
    },

    deleteItem() {
      this.saving = true;
      httpClient.delete(`${process.env.VUE_APP_API_URL}allocation-sort-fields`, this.rows[this.deleteIndex].id)
      .then(({ data }) => {
        this.saving = false;
        this.$notify.success('Campo removido com sucesso');
        this.rows.splice(this.deleteIndex, 1);
        this.deleteIndex = null;
      })
      .catch((error) => {
        this.saving = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    initForm() {
      this.currentRow = {};
      this.setFormDefaultValues();
      return this.currentRow;
    },

    setFormDefaultValues() {
      if (!this.selectedOption) {
        this.selectedOption = {};
      }

      if (!this.currentRow.orderType) {
        this.currentRow.orderType = this.orderType;
      }
    },

    rowSaved(savedRow) {
      savedRow = this.setFieldLabel(savedRow);

      if (this.editIndex > -1) {
        this.rows[this.editIndex] = savedRow;
      } else {
        this.rows.push(savedRow);
      }

      this.loadRow(-1);
    },

    cancelSave() {
      this.loadRow(-1);
    },

    setFieldLabel(row) {
      if (row.field) {
        let option = this.fieldOptions.find(o => o.value === row.field);

        if (option) {
          row.fieldLabel = option.label;
        }
      }

      return row;
    },

    saveRow() {
      if (!this.validateForm() || !this.prepareSave()) {
        return;
      }

      this.saving = true;
      let promise;

      if (this.currentRow.id) {
        promise = httpClient.put(`${process.env.VUE_APP_API_URL}allocation-sort-fields`, this.currentRow);
      } else {
        promise = httpClient.post(`${process.env.VUE_APP_API_URL}allocation-sort-fields`, this.currentRow);
      }

      promise.then(({ data }) => {
        this.saving = false;
        this.$notify.success('Campo salvo com sucesso');
        this.rowSaved(data.data);
      })
      .catch((error) => {
        this.saving = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    getHighestPriotity() {
      let highestPriority = -1;

      for (let row of this.rows) {
        if (row.priority !== undefined && row.priority !== null && row.priority > highestPriority) {
          highestPriority = row.priority;
        }
      }

      return highestPriority;
    },

    validateForm() {
      if (!this.selectedOption || !this.selectedOption.value) {
        this.$notify.textError('Campo não informado');
        return false;
      }

      if (this.findRowWithSameField()) {
        this.$notify.textError('Campo já adicionado');
        return false;
      }

      return true;
    },

    loadRow(index) {
      if (index === -1) {
        this.selectedOption = {};
      }

      this.editIndex = index;
      this.currentRow = this.initForm();

      if (index !== undefined && index > -1) {
        this.currentRow = Object.assign(this.currentRow, this.rows[index]);

        if (this.currentRow.field) {
          this.selectedOption = this.fieldOptions.find(o => o.value === this.currentRow.field);
        }
      }
    },

    prepareSave() {
      this.currentRow.field = this.selectedOption.value;

      if (!this.currentRow.id) {
        this.currentRow.priority = this.getHighestPriotity() + 1;
      }

      return true;
    },

    findRowWithSameField() {
      return this.rows.find(r => this.currentRow.id !== r.id && r.field === this.selectedOption.value);
    },
  }
}
</script>
