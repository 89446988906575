<template>
  <div>
    <div v-if="loading || saving">
      <loading-animation />
    </div>

    <div v-else-if="rule">

      <b-row class="mx-md-n3 mx-1">
        <div class="col-sm-6 col-12">

          <div class="row">
            <div class="col-12 margin-bottom">
              <h5>Tipo</h5>
            </div>

            <div class="col-12">
              <model-list-select
                :list="types"
                option-value="value"
                option-text="label"
                v-model="type"
                placeholder="Digite para pesquisar..."
              />
            </div>

            <div v-if="type && type.hint" class="col-12">
              <small class="text-muted">{{type.hint}}</small>
            </div>
          </div>

        </div>
        <div class="col-sm-6 col-12">

          <b-form-group
            id="description"
            label="Descrição"
            label-for="description"
          >
            <b-form-input
              required
              id="description-input"
              type="text"
              v-model="rule.description"/>
          </b-form-group>

        </div>
      </b-row>

      <div class="card mt-3">
        <div class="card-header">Parâmetros</div>
        <div class="card-body">
          <div v-if="type && type.value">
            <div v-switch="type.value">

              <div v-case="'LOCATION_GROUPER'">
                <location-grouper-view
                  ref="locationGrouperView"
                ></location-grouper-view>
              </div>

              <div v-default>Esse tipo não possui parâmetros</div>
            </div>
          </div>
          <div v-else>
            Selecione o tipo
          </div>
        </div>
      </div>

      <b-button type="button" variant="primary" class="btn-lg float-right" :disabled="saving" @click="save">
        Salvar
      </b-button>

    </div>
  </div>
</template>

<script>
import { LoadingAnimation } from '@/components/loaders';
import shared from '@/shared/shared';
import { ModelListSelect } from 'vue-search-select'
import { httpClient } from '@/service'
import LocationGrouperView from "./params/location-grouper-view";

export default {
  name: 'allocation-rule-form-modal',
  components: { LoadingAnimation, ModelListSelect, LocationGrouperView },

  props: {
    saved: {
      type: Function
    },
    rules: {
      type: Array,
      require
    },
    types: {
      type: Array,
      require
    },
    orderType: {
      type: String,
      require
    }
  },

  data() {
    return {
      rule: null,
      loading: false,
      saving: false,
      type: {}
    }
  },

  mounted() {
  },

  methods: {
    initNewRule() {
      this.rule = {
        orderType: this.orderType
      };
      this.type = {};
      this.rule.params = {};

      return this.rule;
    },

    save() {
      if (!this.validateForm() || !this.prepareSave()) {
        return;
      }

      this.saving = true;
      let promise;

      if (this.rule.id) {
        promise = httpClient.put(`${process.env.VUE_APP_API_URL}allocation-rules`, this.rule);
      } else {
        promise = httpClient.post(`${process.env.VUE_APP_API_URL}allocation-rules`, this.rule);
      }

      promise.then(({ data }) => {
        this.saving = false;
        this.$notify.success('Regra salva com sucesso');
        this.initNewRule();

        if (this.saved) {
          this.saved(data.data);
        }
      })
      .catch((error) => {
        this.saving = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    getHighestPriotity() {
      let highestPriority = -1;

      for (let rule of this.rules) {
        if (rule.priority !== undefined && rule.priority !== null && rule.priority > highestPriority) {
          highestPriority = rule.priority;
        }
      }

      return highestPriority;
    },

    validateForm() {
      if (!this.type || !this.type.value) {
        this.$notify.textError('Tipo não informado');
        return false;
      }

      if (!this.rule.description) {
        this.$notify.textError('Descrição não informada');
        return false;
      }

      if (this.findRuleWithSameDescription(this.rule.description)) {
        this.$notify.textError('Já existe uma regra com essa descrição');
        return false;
      }

      return true;
    },

    loadRule(index) {
      this.rule = this.initNewRule();

      if (index !== undefined && index > -1) {
        this.rule = Object.assign(this.rule, this.rules[index]);

        if (this.rule.ruleCode) {
          this.type = this.types.find(t => t.value === this.rule.ruleCode);
        }
      }
    },

    prepareSave() {
      this.rule.ruleCode = this.type.value;

      if (!this.rule.id) {
        this.rule.priority = this.getHighestPriotity() + 1;
      }

      let newParams = {};

      switch (this.type.value) {
        case 'LOCATION_GROUPER': {
          newParams = this.$refs.locationGrouperView.saveParams();
          break;
        }
      }

      if (newParams) {
        this.rule.params = newParams;
        return true;
      } else {
        return false;
      }
    },

    setDescriptionByType(label) {
      let temp = label;
      let suffix = 2;

      while (this.findRuleWithSameDescription(temp)) {
        temp = label + ' ' + suffix;
        suffix++;
      }

      this.rule.description = temp;
    },

    findRuleWithSameDescription(description) {
      return this.rules.find(r => this.rule.id !== r.id && r.description === description);
    },

    loadTypeComponentParams(type) {
      this.$nextTick(() => {
        if (type && type.value) {
          switch (type.value) {
            case 'LOCATION_GROUPER': {
              this.$refs.locationGrouperView.loadParams(this.rule.params);
              break;
            }
          }
        }
      });
    }
  },

  watch: {
    'type': function(newVal, oldVal) {
      if (!shared.entitiesAreEqual(newVal, oldVal, 'value')) {
        if (!newVal || !newVal.value || newVal.value !== this.rule.ruleCode) {
          this.rule.params = {};
        }

        if (!this.rule.id) {
          this.setDescriptionByType(newVal.label);
        }

        this.loadTypeComponentParams(newVal);
      }
    }
  }
}
</script>
