<template>
  <div class="row">

    <div class="col-sm col-12">
      <div class="col-12 margin-bottom">
        <h5>Agrupador</h5>
      </div>

      <div class="col-12">
        <div v-if="loadingGroupers">
          <loading-animation />
        </div>
        <model-list-select
          v-else
          :list="groupers"
          option-value="id"
          option-text="name"
          v-model="grouper"
          placeholder="Selecione o agrupador"
        />
      </div>
    </div>

    <div class="col-sm col-12">
      <div class="col-12 margin-bottom">
        <h5>Operador</h5>
      </div>

      <div class="col-12">
        <model-list-select
          :list="conditionalOperators"
          option-value="value"
          option-text="label"
          v-model="conditionalOperator"
          placeholder="Selecione o operador"
        />
      </div>
    </div>

    <div class="col-sm col-12">
      <b-form-group
        id="value"
        label="Valor"
        label-for="value"
      >
        <b-form-input
          required
          id="value-input"
          type="text"
          v-model="value"/>
      </b-form-group>
    </div>

  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import { LoadingAnimation } from '@/components/loaders';
import { httpClient } from '@/service'

export default {
  name: 'location-grouper-view',
  components: { ModelListSelect, LoadingAnimation },

  data() {
    return {
      conditionalOperators: [
        { value: '=', label: 'Igual à' },
        { value: '!=', label: 'Diferente de' },
        { value: '>', label: 'Maior que' },
        { value: '<', label: 'Menor que' },
        { value: '>=', label: 'Maior ou igual à' },
        { value: '<=', label: 'Menor ou igual à' }
      ],
      loadingGroupers: false,
      groupers: [],
      grouper: {},
      conditionalOperator: {},
      value: ''
    }
  },

  methods: {
    loadParams(params) {
      this.conditionalOperator = {};
      this.grouper = {};
      this.value = params.value || '';

      if (params.conditionalOperator) {
        this.conditionalOperator = this.conditionalOperators.find(o => o.value === params.conditionalOperator) || {};
      }

      this.loadGroupers(params);
    },

    loadGroupers(params) {
      if (this.groupers.length) {
        this.setGrouperFromGrouperId(params);
      } else {
        this.loadGroupers = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}location-groupers`).then(({ data }) => {
          this.loadGroupers = false;
          this.groupers = data.data.content;
          this.setGrouperFromGrouperId(params);
        })
        .catch((error) => {
          this.loadGroupers = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      }
    },

    setGrouperFromGrouperId(params) {
      if (params.grouperId) {
        this.grouper = this.groupers.find(g => g.id === params.grouperId) || {};
      }
    },

    saveParams() {
      if (!this.grouper || !this.grouper.id) {
        this.$notify.textError('Agrupador não informado');
        return;
      }

      if (!this.conditionalOperator || !this.conditionalOperator.value) {
        this.$notify.textError('Operador não informado');
        return;
      }

      if (!this.value) {
        this.$notify.textError('Valor não informado');
        return;
      }

      let newParams = {};
      newParams.value = this.value;
      newParams.conditionalOperator = this.conditionalOperator.value;
      newParams.grouperId = this.grouper.id;

      return newParams;
    }
  }
}
</script>
